@use "../abstracts/" as a;

.block {
  @at-root #{&}__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  @at-root #{&}-type {
    @at-root #{&}-text {
      text-align: justify;
    }
    @at-root #{&}-list {
      ul {
        margin-left: a.$sp-md;
      }
      li {
        list-style: circle;
      }
    }
  }

  p {
    margin-bottom: a.$sp-md;
  }
}
