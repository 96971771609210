@use "../abstracts/" as a;

.image {
  width: 100%;
  object-fit: cover;

  &.lazyload {
    opacity: 0;
    transition: opacity 0.2s linear;
  }

  &.is-pixelated {
    -ms-interpolation-mode: nearest-neighbor;
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;
  }

  @at-root #{&}__wrapper {
    overflow: hidden;
    position: relative;
  }

  @at-root #{&}__placeholder {
    opacity: 1;
    transition: opacity linear 0.6s;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    &.is-loaded {
      opacity: 0;
    }
  }

  @at-root #{&}__lentil {
    border-radius: 50%;
    overflow: hidden;
    width: 18vw;
    max-height: 12vw;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;

    @at-root #{&}__wrapper {
      @include a.mq(md, "max") {
        display: none;
      }
    }
  }

  @at-root #{&}__card {
    @include a.mq(md, "min") {
      width: 18vw;
      margin: 0 auto;
    }
  }

  @at-root #{&}__edition--link {
    @include a.mq(md, "max") {
      margin: 0 a.$sp-md;
    }
  }
}
