@use "../abstracts" as a;

@each $color, $shades in a.$colors {
  @each $shade, $value in $shades {
    .clr-#{$color}-#{$shade} {
      color: #{$value};
    }
  }
}

@each $space, $value in a.$spaces {
  .my--#{$space} {
    margin: #{$value} 0;
  }

  .mx--#{$space} {
    margin: 0 #{$value};
  }

  @each $prop, $pos in a.$positions {
    .m#{$prop}--#{$space} {
      margin#{$pos}: #{$value};
    }

    [data-m#{$prop}="#{$space}"] {
      margin#{$pos}: #{$value};
    }

    [data-m#{$prop}-neg="#{$space}"] {
      margin#{$pos}: -#{$value};
    }
  }

  .py--#{$space} {
    padding: #{$value} 0;
  }

  .px--#{$space} {
    padding: 0 #{$value};
  }

  @each $prop, $pos in a.$positions {
    .p#{$prop}--#{$space} {
      padding#{$pos}: #{$value};
    }

    [data-p#{$prop}="#{$space}"] {
      padding#{$pos}: #{$value};
    }
  }
}

#pixel-anchor {
  position: absolute;
  z-index: 9999;
  pointer-events: none;
  width: 10px;
  height: 10px;
  left: 0;
  top: 0;

  @include a.mq(md, "min") {
    top: 120px;
  }
}

[data-observer] {
  @include a.append-attr("target") {
    position: absolute;
    z-index: 9999;
    pointer-events: none;
    width: 1px;
    height: 1px;
    top: 40vh;
  }
}

[data-mobile] {
  @include a.append-attr("visible") {
    @include a.mq(md, "min") {
      display: none;
      visibility: hidden;
    }
  }
  @include a.append-attr("image") {
    @include a.mq(md, "max") {
      max-width: 72vw;
    }
  }
}

.hidden-input {
  position: fixed;
  top: -10000px;
  left: -10000px;
  opacity: 0;
}

.spacer {
  display: none;
  @include a.mq(md, "min") {
    display: block;
    height: 22vh;
    width: 100%;
  }
}

.container {
  width: 100%;
  max-width: a.$bp-lg;
  margin: 0 auto;

  &--large {
    max-width: a.$bp-xl;
  }

  &--fluid {
    margin: 0;
    max-width: 100%;
  }

  &__row {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
  }
}

.sticky {
  @include a.mq(md, "min") {
    position: -webkit-sticky;
    position: sticky;
    top: a.$navbar-height;
  }
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.fixed {
  position: fixed;
}

.upper-case {
  text-transform: uppercase;
}

.lower-case {
  text-transform: lowercase;
}

.no-transition {
  transition: none !important;
}

.border-top {
  border-top: 1px solid a.$secondary-100;
  width: 100%;
}

.border-bottom {
  border-bottom: 1px solid a.$secondary-100;
  width: 100%;
}

.is-hidden {
  @include a.hidden();
}

.is-disabled {
  z-index: 0;
  pointer-events: none;
}

.is-blured {
  @include a.mq(md, "min") {
    filter: blur(a.$sp-md);
  }
  opacity: 0.6;
  pointer-events: none;
}

.is-disabled {
  pointer-events: none;
}

.is-invisible {
  @include a.mq(md, "min") {
    filter: blur(a.$sp-xl);
  }
  opacity: 0;
  transform: scale(0.94);
}
