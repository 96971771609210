@use "../abstracts" as a;

@for $i from 1 through 6 {
  h#{$i} {
    font-weight: 400;
    font-size: a.$text-size;
  }
}

@for $i from 1 through 2 {
  h#{$i} {
    font-family: a.$font-text--bold;
  }
}
@for $i from 3 through 6 {
  h#{$i} {
    font-family: a.$font-text;
  }
}

.headline {
  @include a.headline();

  @at-root #{&}--big {
    @include a.mq(md, "min") {
      transform: scale(2);
      padding: var(--headline-height) 0;
    }
  }

  @at-root #{&}--edition {
    font-size: a.$fs-500;
    @include a.mq(md, "min") {
      font-size: clamp(a.$fs-500, 7.2vw, a.$fs-600);
    }
  }

  @at-root #{&}__wrapper {
    position: relative;
  }
}

.text {
  @at-root #{&}--centered {
    text-align: center;
  }
  @at-root #{&}--justified {
    text-align: justify;
  }
  @at-root #{&}--big {
    font-size: a.$fs-300;
  }
}
