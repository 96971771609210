@use "../abstracts/" as a;
@use "../utilities/animations";

[data-carousel] {
  @include a.append-attr("trigger") {
    @include a.mq(md, "max") {
      visibility: hidden;
      display: none;
    }
    visibility: visible;
    display: inline-block;
    border-radius: 50%;
    transition: transform 0.2s a.$easing-secondary;
    cursor: pointer;
    &:focus-visible,
    &:hover {
      transform: scale(1.18);
    }
  }
}

[data-carousel-type] {
  @include a.append-attr("image") {
    width: 100%;
    @include a.mq(md, "min") {
      cursor: a.$cursor-cross;
      margin: 0;
      position: fixed;
      width: 100%;
      height: 100%;
      padding: a.$sp-lg 0;
      top: 0;
      left: 0;
      z-index: 100;
      pointer-events: none;
      opacity: 0;
      filter: blur(a.$sp-xl);
      transform: translate3d(0, 0, 0);
      transition: opacity 0.8s a.$easing-primary, filter 0.8s a.$easing-primary;
    }
    &.is-active {
      @include a.mq(md, "min") {
        filter: blur(0);
      }
      opacity: 1;
      pointer-events: all;
    }

    .carousel {
      @at-root #{&}__item {
        margin-bottom: a.$sp-sm;
        @include a.mq(md, "min") {
          margin-left: a.$sp-md;
          width: fit-content;
          height: 100%;
          flex-shrink: 0;
          flex-grow: 0;
          scroll-snap-align: center;
          scroll-snap-stop: always;
          &:last-of-type {
            margin-right: a.$sp-md;
            scroll-snap-align: end;
          }
        }
        & .image {
          @include a.mq(md, "min") {
            height: 100%;
          }
          @at-root #{&}__wrapper {
            @include a.mq(md, "min") {
              width: fit-content;
              height: 100%;
            }
          }
        }
      }
    }
  }
  @include a.append-attr("text") {
    .carousel__item {
      scroll-snap-align: center;
      scroll-snap-stop: always;
      &:last-of-type {
        scroll-snap-align: end;
      }
    }
  }
}

[data-carousel-nav] {
  @include a.append-attr("mouse") {
    .carousel__navigation {
      height: 100%;
      width: 50%;

      @at-root #{&}--prev {
        cursor: a.$cursor-leftArrow;
      }
      @at-root #{&}--next {
        cursor: a.$cursor-rightArrow;
      }

      @at-root #{&}--hidden {
        opacity: 0;
        pointer-events: none;
      }

      @at-root #{&}__wrapper {
        @include a.mq(md, "max") {
          display: none;
          visibility: hidden;
        }
        position: absolute;
        width: 100%;
        height: calc(100% - (2 * a.$sp-lg));
        top: a.$sp-lg;
        left: 0;
      }
    }
  }

  @include a.append-attr("button") {
    .carousel__navigation {
      @include a.mq(md, "min") {
        position: absolute;
        top: 0;
        width: 5.2vw;
        height: 4vw;
        background-color: a.$primary-100;
        transition: opacity 0.6s a.$easing-primary;

        @at-root #{&}--prev {
          left: 0;
          &::after {
            content: a.$leftArrow;
          }
        }
        @at-root #{&}--next {
          right: 0;
          &::after {
            content: a.$rightArrow;
          }
        }
        &[aria-disabled="true"] {
          opacity: 0;
          pointer-events: none;
        }
      }

      @at-root #{&}__wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
      }
    }
  }
}

.carousel {
  scroll-behavior: smooth;
  scroll-snap-type: x proximity;
  @include a.mq(md, "min") {
    height: 100%;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  @at-root #{&}__container {
    @include a.mq(md, "min") {
      display: flex;
      flex-flow: row nowrap;
      height: 100%;
      width: max-content;
      transition: transform 0.4s cubic-bezier(0.16, 1, 0.3, 1);
    }
  }
}
