@use "../abstracts/" as a;

.search {
  @at-root #{&}__wrapper {
    height: a.$navbar-height--mobile;
    padding: a.$sp-xs 0;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    position: absolute;
    z-index: 100;
    left: 50%;
    top: 48%;
    transform: translate3d(-50%, -220%, 0);
    width: 94vw;
    background-color: a.$primary-100;
    border-bottom: solid 3px a.$secondary-100;
    transition: a.$easing-secondary 0.4s transform;
    @include a.mq(md, "min") {
      width: 78%;
      height: 86%;
    }

    &.is-active {
      transform: translate3d(-50%, -50%, 0);
    }

    .icon {
      pointer-events: none;
      width: a.$fs-300;
    }
  }

  @at-root #{&}__form {
    display: flex;
    width: 100%;
    height: 100%;
  }

  @at-root #{&}__input {
    padding: 0 a.$sp-sm;
    border: none;
    background-color: transparent;
    width: 100%;
    height: 100%;
    @include a.headline();
    @include a.mq(md, "min") {
      margin-right: a.$sp-sm;
    }

    &:focus-within {
      outline: none;
    }
  }

  @at-root #{&}__label {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @at-root #{&}__submit {
    width: a.$fs-400;
    height: a.$fs-400;
    cursor: pointer;
    background-color: transparent;
    border: none;

    @at-root #{&}__wrapper {
      position: relative;

      .icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
      }
    }
  }

  @at-root #{&}__close {
    cursor: pointer;
    border-radius: a.$sp-xs;
  }
}

[data-search] {
  @include a.append-attr("text") {
    max-width: 68ch;
    margin: a.$sp-md auto;
    text-align: justify;
  }

  @include a.append-attr("highlight") {
    color: a.$primary-100;
    background-color: a.$secondary-100;
    border-radius: 0.4rem;
    padding: 0 0.4rem;
    margin: 0 0.2rem;
  }
}
