@use "../abstracts/" as a;

.hamburger {
  @at-root #{&}__label {
    display: block;
    position: relative;
    @include a.icon-size();

    .icon {
      @include a.icon-size();
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      /* transition: opacity 0.2s a.$easing-primary,
        transform 0.2s a.$easing-primary; */

      @at-root #{&}--hamburger {
        opacity: 1;
        /* transform-origin: left;
        transform: rotateY(0deg) scaleX(1) translate3d(-50%, -50%, 0); */
      }

      @at-root #{&}--cross {
        opacity: 0;
        /* transform-origin: right;
        transform: rotateY(-90deg) scaleX(0) translate3d(250%, -50%, 0); */
      }
    }
  }

  @at-root #{&}__input {
    &:checked {
      & ~ .hamburger__label {
        & > .icon--cross {
          opacity: 1;
          /* transform-origin: left;
          transform: rotateY(0deg) scaleX(1) translate3d(-50%, -50%, 0); */
        }
        & > .icon--hamburger {
          opacity: 0;
          /* transform-origin: right;
          transform: rotateY(90deg) scaleX(0) translate3d(-350%, -50%, 0); */
        }
      }
      & ~ .mobile__label {
        opacity: 0;
        /* transform: translate3d(-50%, -50%, 0) scale(0.8); */
        pointer-events: none;
      }
    }
  }
}
