@use "../abstracts" as a;

.flex {
  display: flex;
  @include a.just-align("align", "align-items", a.$align-positions);
  @include a.just-align("justify", "justify-content", a.$justify-positions);
  @include a.just-align("dir", "flex-direction", a.$flex-directions);
}

@each $key, $value in a.$breakpoints {
  @include a.hide-on-screen-size($key);
}
