@use "../abstracts/" as a;

.infos {
  @at-root #{&}__wrapper {
    position: relative;
    padding-bottom: a.$sp-md;
    width: 100%;
    @include a.mq(md, "min") {
      padding-top: a.$sp-md;
      padding-bottom: a.$sp-xl;
    }
    &:before {
      position: absolute;
      top: 0;
      @include a.horizontal-line();
    }
    &:after {
      margin-top: a.$sp-md;
      @include a.mq(md, "min") {
        margin-top: a.$sp-xl;
      }
      @include a.horizontal-line();
    }
  }

  @at-root #{&}__container {
    &:not(:first-of-type) {
      margin-top: a.$sp-md;
    }
  }

  @at-root #{&}__block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include a.mq(md, "max") {
      margin: a.$sp-md 0;
    }

    p {
      margin-top: 0.4rem;
    }
  }
}
