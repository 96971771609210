@use "../abstracts/" as a;

footer {
  padding: 0 1px;
  margin-top: auto;
  background-color: a.$primary-100;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 8;
  transition: transform 0.6s a.$easing-primary;
  padding: 0 a.$sp-md;
  width: 100%;

  &.is-away {
    transform: translateY(100%);
  }
}
