@use "../abstracts/" as a;

.years-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 100%;

  @at-root #{&}__wrapper {
    position: -webkit-sticky;
    overflow-y: hidden;
    overflow-x: auto;
    position: sticky;
    z-index: 6;
    left: 0;
    top: a.$navbar-height--mobile;
    background-color: a.$primary-100;
    width: 100%;
    transition: transform 0.58s a.$easing-primary;
    @include a.mq(md, "min") {
      top: a.$navbar-height;
    }
    &.is-away--mobile {
      @include a.mq(md, "max") {
        transform: translateY(a.$navbar-height--mobile);
      }
    }
    &.is-away {
      transform: translateY(-120%);
    }
  }

  @at-root #{&}__item {
    min-width: max-content;
    padding: a.$sp-xs a.$sp-sm;
    transition: opacity 0.5s a.$easing-primary, filter 0.5s a.$easing-primary;
  }
}
