@use "../abstracts/" as a;

.modal {
  @at-root #{&}__label {
    cursor: a.$cursor-cross;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @include a.mq(md, "max") {
      &:after {
        content: attr(data-close);
        font-family: a.$font-display;
        background-color: a.$primary-100;
        padding: a.$sp-sm;
        padding-top: a.$sp-md;
        font-size: a.$fs-300;
        text-transform: uppercase;
        border-radius: 2rem;
        border: solid 1px a.$secondary-100;
        position: fixed;
        bottom: 10px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  @at-root #{&}__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0;
    right: 0;
    top: 0;
    left: 0;
    z-index: 9999;
    transition: opacity 0.6s a.$easing-primary, transform 0.6s a.$easing-primary, filter 0.6s a.$easing-primary;
    padding: a.$sp-lg a.$sp-md;

    & .image,
    & .image__wrapper,
    & .picture {
      @include a.mq(md, "min") {
        width: auto;
      }
      max-width: 96vw;
      min-height: 90vh;
    }
  }
}
