@use "../abstracts" as a;

[data-display="grid"] {
  @include a.mq(md, "min") {
    display: grid;
    width: 100%;
    place-items: center;

    & .exhibit-list__item {
      padding: 0;
    }

    &[data-flow] {
      @include a.append-attr("column-few") {
        grid-template-columns: repeat(2, 1fr);
        grid-auto-flow: row;
        grid-auto-rows: 1fr;
        gap: 3rem 32%;
      }
      @include a.append-attr("column-lots") {
        grid-template-rows: repeat(3, 1fr);
        @include a.column;
      }
      @include a.append-attr("row") {
        grid-template-columns: 1.2fr repeat(10, 1fr) 1.2fr;
        grid-auto-flow: row;
        grid-auto-rows: 1fr;
      }
    }

    &[data-gap] {
      @include a.append-attr("xs") {
        gap: a.$sp-xs;
      }
      @include a.append-attr("sm") {
        gap: a.$sp-sm;
      }
      @include a.append-attr("md") {
        gap: a.$sp-md;
      }
      @include a.append-attr("lg") {
        gap: a.$sp-lg;
      }
    }

    &[data-place] {
      @include a.append-attr("center") {
        & * {
          place-self: center;
        }
      }
      @include a.append-attr("stretch") {
        & * {
          place-self: stretch;
        }
      }
      @include a.append-attr("top-stretch") {
        & * {
          place-self: top stretch;
        }
      }
      @include a.append-attr("center-stretch") {
        & * {
          place-self: center stretch;
        }
      }
    }

    &[data-space] {
      @include a.append-attr("2") {
        & *:nth-child(2n) {
          grid-column-start: 8;
          grid-column-end: 13;
        }
      }
      @include a.append-attr("4") {
        & *:nth-child(2n) {
          grid-column-start: 8;
          grid-column-end: 13;
        }
      }
    }
  }
}

@for $i from 1 through 12 {
  [data-span="#{$i}"] {
    @include a.mq(md, "min") {
      grid-column: span $i;
    }
  }
  [data-start="#{$i}"] {
    @include a.mq(md, "min") {
      grid-column-start: $i;
    }
  }
  [data-end="#{$i}"] {
    @include a.mq(md, "min") {
      grid-column-end: $i;
    }
  }
}
