@use "../abstracts" as a;

.list {
  position: relative;
  @at-root #{&}__wrapper {
    width: 100%;
    position: relative;
  }

  @at-root #{&}__years {
    .list__item {
      @include a.mq(md, "min") {
        padding: a.$sp-lg 0;
      }
    }
  }

  @at-root #{&}__link {
    text-decoration: none;
    overflow-y: hidden;
    & .pill {
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &:focus-visible {
      @include a.focus();
    }

    .icon {
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      position: absolute;
      max-height: 2rem;
      @include a.mq(md, "min") {
        max-height: a.$pill-size;
      }
    }
  }

  @at-root #{&}__item {
    display: grid;
    grid-template-rows: 1fr;
    width: 100%;
    transition: grid-template-rows 0.6s a.$easing-primary,
      transform 0.6s a.$easing-primary, opacity 0.6s a.$easing-primary,
      filter 0.6s a.$easing-primary, margin 0.6s a.$easing-primary,
      padding 0.6s a.$easing-primary;
    position: relative;
    z-index: 3;
    transform-origin: top;
    margin-bottom: -1px;
    @include a.mq(md, "max") {
      padding: a.$sp-md 0;
    }

    &.not-selected {
      opacity: 0.4;
      z-index: 0;
    }

    &.is-invisible {
      grid-template-rows: 0fr;
      margin: 0 auto;
      padding: 0;
    }

    @at-root #{&}--lentil {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate3d(-50%, -50%, 0);
      width: fit-content;
      pointer-events: none;

      .image {
        opacity: 0;
        filter: blur(4rem);
        transform: translate3d(0, 0, 0);
        transition: opacity 0.6s a.$easing-primary, filter 1s a.$easing-primary;
      }

      &.is-active {
        .image {
          opacity: 1;
          filter: blur(0);
          transform: translate3d(0, 0, 0);
          pointer-events: all;
        }
      }
    }

    @at-root #{&}--py {
      & .title__container {
        padding: a.$sp-md 0;
      }
    }
  }

  @at-root #{&}--hero-image {
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: max-content;
  }
}

[data-list] {
  @include a.append-attr("edition") {
    padding-bottom: a.$sp-sm;
    .list__item {
      padding: 0;
      margin-top: -1px;
    }
  }
}
