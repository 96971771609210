@use "../abstracts" as a;

:root {
  @each $size, $value in a.$spaces {
    --sp-#{$size}: #{$value};
  }

  @each $size, $value in a.$type-scale {
    --fs-#{$size}: #{$value};
  }

  @each $key, $value in a.$breakpoints {
    --bp-#{$key}: #{$value};
  }

  @each $color, $shades in a.$colors {
    @each $shade, $value in $shades {
      --clr-#{$color}-#{$shade}: #{$value};
    }
  }
}

::-moz-selection {
  color: a.$primary-100;
  background: a.$secondary-200;
}

::selection {
  color: a.$primary-100;
  background: a.$secondary-200;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
  font-size: 12px;
  @include a.mq(md, "min") {
    font-size: 14px;
  }
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: a.$navbar-height--mobile a.$sp-md;
  min-height: 100vh;
  display: flex;
  font-size: 1.4rem;
  font-family: a.$font-text;
  font-size: a.$text-size;
  line-height: 107%;
  text-justify: inter-character;
  flex-direction: column;
  background-color: a.$primary-100;
  color: a.$secondary-100;

  @each $key, $value in a.$breakpoints {
    @include a.print-screen-size($key);
  }

  @include a.mq(md, "min") {
    max-width: calc(100vw - 16px);
  }
}

em {
  font-style: oblique 0deg;
  font-family: a.$font-text--italic;
}

strong {
  font-family: a.$font-text--bold;
}

button {
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

input {
  border-radius: a.$sp-xs;
}

label {
  cursor: pointer;
}

input:focus-visible + label,
a:focus-visible,
button:focus-visible,
input:focus-visible,
div:focus-visible,
section:focus-visible {
  @include a.focus();
}
