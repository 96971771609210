@use "../abstracts" as a;

.pill {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: a.$fs-100;
  font-family: a.$font-display;
  text-transform: uppercase;
  width: fit-content;
  padding: 0.14em 0.42em 0.1em 0.42em;
  border: 2px solid a.$secondary-100;
  background-color: a.$primary-100;
  transition: color 0.2s a.$easing-primary, background-color 0.4s a.$easing-secondary;
  text-decoration: none;

  @at-root #{&}--rounded {
    border-radius: 6rem;
  }

  @at-root #{&}--full-width {
    width: 100%;
  }

  @at-root #{&}--reverse {
    background-color: a.$secondary-100;
    color: a.$primary-100;
  }
}
