@use "../abstracts/" as a;

.icon {
    @at-root #{&}__content {
        fill: a.$secondary-100;
        transition: fill .2s a.$easing-primary;
    }

    @at-root #{&}__background {
        fill: a.$primary-100;
        transition: fill .2s a.$easing-primary;
    }

    @at-root #{&}__border {
        fill: a.$secondary-100;
        transition: fill .2s a.$easing-primary;
    }

    &.is-active {
        .icon__background {
            fill: a.$secondary-100
        }

        .icon__content {
            fill: a.$primary-100
        }
    }
}