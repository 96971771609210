@use "../abstracts/" as a;

.article {
  @at-root #{&}__wrapper {
    position: relative;
    width: 100%;
  }
  @at-root #{&}__header {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: a.$sp-md 0 a.$sp-xl 0;
  }
  @at-root #{&}__main {
    position: relative;
    z-index: 8;
    background-color: a.$primary-100;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  @at-root #{&}__image {
    position: sticky;
    top: 48vh;
    @include a.mq(md, "min") {
      margin-bottom: 24vh;
    }
  }
}
