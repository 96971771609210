@use "../abstracts/" as a;
main {
  opacity: 0;
  transition: opacity 0.8s a.$easing-secondary;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &.is-leaving {
    opacity: 0;
  }

  &.is-current {
    opacity: 1;
  }
}
