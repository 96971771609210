@font-face {
  font-family: "Display";
  src: url("../fonts/AUTHENTIC-Sans-Pro-Condensed-130.woff");
  src: url("../fonts/AUTHENTIC-Sans-Pro-Condensed-130.woff2");
}

@font-face {
  font-family: "Text";
  font-weight: 300;
  src: url("../fonts/JHA_Times_Now_SemiLight.woff");
  src: url("../fonts/JHA_Times_Now_SemiLight.woff2");
}

@font-face {
  font-family: "Text--italic";
  font-weight: 300;
  src: url("../fonts/JHA_Times_Now_SemiLight_Italic.woff");
  src: url("../fonts/JHA_Times_Now_SemiLight_Italic.woff2");
}

@font-face {
  font-family: "Text--bold";
  font-weight: 600;
  src: url("../fonts/JHA_Times_Now_SemiBold.woff");
  src: url("../fonts/JHA_Times_Now_SemiBold.woff2");
}
