@use "../abstracts" as a;

.title {
  @at-root #{&}__wrapper {
    @include a.mq(md, "min") {
      margin-top: a.$sp-lg;
      margin-bottom: a.$sp-xl;
      position: relative;
    }

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding-bottom: a.$sp-lg;
    background-color: a.$primary-100;
    z-index: 9;
  }

  @at-root #{&}__container {
    width: 100%;
  }

  @at-root #{&}__primary {
    max-width: clamp(8ch, 100%, 16ch);
    margin: 0 auto;

    &.headline--edition {
      max-width: 12ch;
    }
  }

  @at-root #{&}__second {
    max-width: clamp(24ch, 100%, 46ch);
    margin: 0 auto;
  }

  @at-root #{&}__third {
    padding-top: 0.4rem;
  }
}
