@use "../abstracts/" as a;

.content {
  @at-root #{&}__wrapper {
    background-color: a.$primary-100;
    padding: a.$sp-md 0 a.$sp-xl 0;
    @at-root #{&}--default {
      @include a.mq(md, "min") {
        max-width: clamp(a.$bp-md, 60vw, a.$bp-lg);
      }
    }
  }

  @at-root #{&}__container {
    @at-root #{&}--default {
      @include a.mq(md, "max") {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
      }
      & p {
        text-align: left;
      }
      & [data-span="12"] {
        p {
          text-align: center;
        }
      }
      & h3 {
        text-align: center;
        margin-top: a.$sp-xl;
        @include a.headline();
        @include a.mq(md, "min") {
          font-size: clamp(a.$fs-500, 12vw, a.$fs-600);
        }
      }
      & .block-type-heading {
        padding-bottom: a.$sp-md;
        margin-bottom: a.$sp-md;
        &:after {
          margin-top: a.$sp-md;
          @include a.horizontal-line();
        }
      }
    }
  }
}
