$breakpoints: (
  "xs": 0rem,
  "sm": 24rem,
  "md": 68rem,
  "lg": 86rem,
  "xl": 162rem,
);

$type-scale: (
  "100": 1rem,
  "200": 1.6rem,
  "300": 3rem,
  "400": 4.2rem,
  "500": 5.8rem,
  "600": 10.8rem,
);

$spaces: (
  "none": 0,
  "auto": auto,
  "xs": 0.4rem,
  "sm": 1rem,
  "md": 1.8rem,
  "lg": 3.2rem,
  "xl": 8.2rem,
  "xxl": 12.6rem,
);

$colors: (
  primary: (
    100: hsl(0, 0%, 0%),
    200: hsl(0, 0%, 20%),
    300: hsl(0, 0%, 40%),
    400: hsl(0, 0%, 60%),
    500: hsl(0, 0%, 80%),
  ),
  secondary: (
    100: hsl(0, 0%, 100%),
    200: hsl(0, 0%, 92%),
    300: hsl(0, 0%, 84%),
    400: hsl(0, 0%, 76%),
    500: hsl(0, 0%, 62%),
  ),
);

$positions: (
  "": "",
  "t": "-top",
  "r": "-right",
  "b": "-bottom",
  "l": "-left",
);

$flex-directions: (
  "row": "row",
  "row-r": "row-reverse",
  "col": "column",
  "col-r": "column-reverse",
);

$justify-positions: (
  "start": flex-start,
  "end": flex-end,
  "center": center,
  "around": space-around,
  "between": space-between,
  "evenly": space-evenly,
);

$align-positions: (
  "start": flex-start,
  "end": flex-end,
  "center": center,
  "stretch": stretch,
  "baseline": baseline,
);

$primary-100: var(--clr-primary-100);
$primary-200: var(--clr-primary-200);
$primary-300: var(--clr-primary-300);
$primary-400: var(--clr-primary-400);
$primary-500: var(--clr-primary-500);

$secondary-100: var(--clr-secondary-100);
$secondary-200: var(--clr-secondary-200);
$secondary-300: var(--clr-secondary-300);
$secondary-400: var(--clr-secondary-400);
$secondary-500: var(--clr-secondary-500);

$fs-100: var(--fs-100);
$fs-200: var(--fs-200);
$fs-300: var(--fs-300);
$fs-400: var(--fs-400);
$fs-500: var(--fs-500);
$fs-600: var(--fs-600);

$sp-auto: var(--sp-auto);
$sp-xs: var(--sp-xs);
$sp-sm: var(--sp-sm);
$sp-md: var(--sp-md);
$sp-lg: var(--sp-lg);
$sp-xl: var(--sp-xl);
$sp-xxl: var(--sp-xxl);

$bp-xs: var(--bp-xs);
$bp-sm: var(--bp-sm);
$bp-md: var(--bp-md);
$bp-lg: var(--bp-lg);
$bp-xl: var(--bp-xl);

$font-display: "Display", Arial, sans-serif;
$font-text: "Text", "Times New Roman", Times, serif;
$font-text--bold: "Text--bold", "Times New Roman", Times, serif;
$font-text--italic: "Text--italic", "Times New Roman", Times, serif;

$easing-primary: cubic-bezier(0.65, 0, 0.35, 1);
$easing-secondary: cubic-bezier(0.61, 1, 0.88, 1);

$navbar-height: clamp(7.4rem, 8vh, 7.8rem);
$navbar-height--mobile: 7rem;
$pill-size: clamp(1.8rem, 1.6vw, 3rem);
$icon-size: 3.6vw;
$text-size: clamp(1.2rem, 1.6vw, 1.6rem);

$cross: url("data:image/svg+xml,%3Csvg width='48' height='48' fill='white' viewBox='0 0 27 27' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath class='icon__content' d='M26.8932 2.36027L25.2694 0.738037L13.7778 12.2184L2.2862 0.738037L0.662384 2.37161L12.154 13.8407L0.662384 25.321L2.2862 26.9433L13.7778 15.4629L25.2694 26.9433L26.8932 25.321L15.4016 13.8407L26.8932 2.36027Z'/%3E%3C/svg%3E");
$leftArrow: url("data:image/svg+xml,%3Csvg width='48' height='48' fill='white' viewBox='0 0 27 26' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.2964 25.7401L14.011 24.2086L4.86997 14.0215L26.2975 14.0215L26.2975 11.73L4.86997 11.73L14.011 1.54282L12.2964 1.3565e-06L0.759342 12.8757L12.2964 25.7401Z'/%3E%3C/svg%3E");
$rightArrow: url("data:image/svg+xml,%3Csvg width='48' height='48' fill='white' viewBox='0 0 27 27' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath class='icon__content' d='M14.7605 0.953613L13.0458 2.48509L22.1869 12.6722H0.759338V14.9638H22.1869L13.0458 25.1509L14.7605 26.6937L26.2975 13.818L14.7605 0.953613Z'/%3E%3C/svg%3E");

$cursor-cross: $cross 24 24, auto;
$cursor-leftArrow: $leftArrow 24 24, auto;
$cursor-rightArrow: $rightArrow 24 24, auto;
