@use "../abstracts" as a;

.navbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  @include a.mq(md, "max") {
    overflow: hidden;
    transition: padding 0.6s a.$easing-primary;
  }
  @include a.mq(md, "min") {
    justify-content: space-between;
  }

  @at-root #{&}__wrapper {
    transition: transform 0.62s a.$easing-primary;
    background-color: a.$primary-100;
    width: 100%;
    height: a.$navbar-height;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 12;
    padding: 0 a.$sp-md;
    @include a.mq(md, "max") {
      height: a.$navbar-height--mobile;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: a.$sp-sm a.$sp-md;
    }

    &.is-away {
      transform: translateY(-100%);
      @at-root #{&}--mobile {
        @include a.mq(md, "max") {
          transform: translateY(-100%);
        }
      }
    }
  }

  @at-root #{&}__container {
    display: grid;
    grid-template-rows: 0fr;
    padding: 0;
    width: 100%;
    @include a.mq(md, "max") {
      display: block;
      background-color: a.$primary-100;
      position: fixed;
      left: 0;
      top: a.$navbar-height--mobile;
      padding: a.$sp-sm 0;
      transform-origin: top;
      transform: translateY(-200%);

      /* & > .navbar > li {
        opacity: 0.3;
        transform: scale(0.6);
        transition: opacity 0.6s a.$easing-primary,
          transform 0.6s a.$easing-primary;
      } */
    }
    @include a.mq(md, "min") {
      display: block;
    }

    &.is-open {
      //grid-template-rows: 1fr;
      transform: translateY(0%);
      /* & > .navbar {
        padding: a.$sp-sm 0;
         & > li {
          opacity: 1;
          transform: scale(1);
        }
      } */
    }
  }

  @at-root #{&}__pills {
    width: 100%;
    display: flex;
    justify-content: center;
    @include a.mq(md, "max") {
      opacity: 0;
      width: 58%;
      position: fixed;
      top: 3.7rem;
      left: 54%;
      transform: translate3d(-50%, -50%, 0);
      /* transition: transform 0.4s a.$easing-primary; */
      pointer-events: none;

      &.is-open {
        opacity: 1;
        pointer-events: all;
        /* transform: translate3d(-50%, -50%, 0); */
      }
    }
    @include a.mq(md, "min") {
      flex-direction: column;
      align-items: stretch;
      margin: a.$sp-xs 0;
    }

    @at-root #{&}__row {
      display: flex;
      justify-content: space-around;
      align-items: center;
    }

    .icon {
      height: a.$pill-size;
      margin: 0.2rem;
    }
  }

  @at-root #{&}__pill {
    cursor: pointer;
    border-radius: a.$sp-md;

    @include a.mq(md, "max") {
      margin: 0 a.$sp-xs;
    }
  }

  @at-root #{&}__item {
    padding: a.$sp-xs;
  }

  @at-root #{&}__logo {
    min-height: a.$fs-300;
    height: 8vw;
    max-height: a.$fs-500;

    @at-root #{&}__link {
      border-radius: 50%;
      padding: a.$sp-xs;
      @include a.mq(md, "max") {
        max-width: 5rem;
      }
    }
  }

  @at-root #{&}__search {
    @at-root #{&}--icon {
      height: a.$fs-100;
    }
  }

  @at-root #{&}__mobile {
    @include a.mq(md, "min") {
      display: none;
    }

    @at-root #{&}__headline {
      position: absolute;
      top: 50%;
      left: 52%;
      transform: translate3d(-50%, -50%, 0);
    }
  }
}

/* .mobile {
  @at-root #{&}__label {
    transition: opacity 0.4s a.$easing-primary, transform 0.4s a.$easing-primary;
  }
}
 */
