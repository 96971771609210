@use "../abstracts/" as a;

a {
  color: white;
}

.link {
  transition: opacity 0.4s a.$easing-secondary, filter 0.4s a.$easing-primary,
    transform 0.4s a.$easing-primary;
  @at-root #{&}--back {
    text-decoration: none;
    align-self: flex-start;
    @include a.mq(md, "min") {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
    }
    .icon {
      transform: rotate(-180deg);
      height: 2rem;
      margin: a.$sp-sm 0;
      @include a.mq(md, "min") {
        margin: 0 a.$sp-md;
        height: a.$icon-size;
      }
    }
  }

  @at-root #{&}--reverse {
    text-decoration: none;
  }
}

[data-reverse] {
  @include a.append-attr("link") {
    display: block;
    position: relative;
  }

  @include a.append-attr("word") {
    pointer-events: none;
    span {
      will-change: transform;
      transform-style: preserve-3d;
      display: inline-block;
      transform-origin: left;
      transition-timing-function: a.$easing-primary;
      transition: 0.3s, font-size 0.4s ease-out 0s;
      transform: translate3d(0%, 0, 0) rotateY(0deg) scaleX(1);
      /* @include a.mq(md, "min") {
        transition-delay: calc(0.01s * var(--index));
      } */
    }
  }

  [data-copy="word"] {
    position: absolute;
    top: 0;
    left: 0;

    span {
      transform-origin: right;
      transform: translate3d(100%, 0, 0) rotateY(-90deg) scaleX(0);
    }
  }

  &.is-active,
  &:hover,
  &:focus-visible,
  &:focus-within {
    [data-reverse="word"] span {
      transform-origin: right;
      transform: translate3d(-100%, 0, 0) rotateY(-90deg) scaleX(0);
    }

    [data-copy="word"] span {
      transform-origin: left;
      transform: translate3d(100%, 0, 0) rotateY(0deg) scaleX(-1);
    }
  }
}
