@keyframes slide {
  from {
    transform: translate3d(0%, 0, 0);
  }
  to {
    transform: translate3d(-50%, 0, 0);
  }
}

@keyframes rotateWord {
  from {
    transform: translate3d(0%, 0, 0) rotateY(0deg) scaleX(1);
  }
  to {
    transform: translate3d(-100%, 0, 0) rotateY(-90deg) scaleX(0);
  }
}

@keyframes flipElement {
  from {
    transform: scaleX(1);
  }
  to {
    transform: scaleX(-1);
  }
}
