@use "../abstracts/" as a;

.place-toggle {
  @at-root #{&}__wrapper {
    position: relative;
  }

  @at-root #{&}__label {
    position: relative;
    &.none {
      position: absolute;
      top: 0;
      left: 0;
      pointer-events: 1;
    }
  }
}
