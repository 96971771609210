@use "../abstracts/variables" as v;
@use "sass:map";

@mixin append-attr($x) {
  $sel: &;
  $collector: ();

  @for $i from 1 through length($sel) {
    $s: nth($sel, $i);
    $last: nth($s, -1);
    @if str-slice($last, -1) == "]" {
      $offset: -1;
      $current-x: $x;

      @if str-slice($last, -2) == '"]' {
        $offset: -2;
      } @else {
        $current-x: '="' + $x + '"';
      }
      $last: str-slice($last, 1, $offset - 1) +
        $current-x +
        str-slice($last, $offset);
      $collector: append($collector, set-nth($s, -1, $last), comma);
    } @else {
      $collector: append($collector, selector-append($s, $x), comma);
    }
  }

  @at-root #{$collector} {
    @content;
  }
}

@mixin mq($key, $min-or-max) {
  $size: map.get(v.$breakpoints, $key);

  @media (#{$min-or-max}-width: $size) {
    @content;
  }
}

@mixin print-screen-size($bp-key) {
  &:before {
    display: none;
    visibility: hidden;
    @include mq($bp-key, "min") {
      content: "#{$bp-key}";
    }
  }
}

@mixin hide-on-screen-size($bp-key) {
  @include mq($bp-key, "max") {
    .hidden-#{$bp-key}-down {
      display: none !important;
    }
  }

  @include mq($bp-key, "min") {
    .hidden-#{$bp-key}-up {
      display: none !important;
    }
  }
}

@mixin just-align($name, $prop, $pos) {
  @each $key, $value in v.$breakpoints {
    @include mq($key, "min") {
      @each $mod, $dir in $pos {
        &-#{$key}--#{$name}-#{$mod} {
          #{$prop}: #{$dir};
        }
      }
    }
  }
}

@mixin button-color(
  $bgColor: v.$light-100,
  $color: v.$primary-100,
  $border: 1px,
  $borderColor: v.$secondary-100,
  $bgHover: v.$secondary-100
) {
  background-color: $bgColor;
  border: $border solid $borderColor;

  & * {
    color: $color;
    transition: color v.$easing-primary 0.2s;
    text-decoration: none;
  }

  @include mq(md, "min") {
    &:hover {
      background-color: $bgHover;
      border-color: $bgHover;
      text-decoration: none;

      & * {
        color: v.$light-100;
      }
    }
  }
}

@mixin title() {
  font-weight: normal;
  font-size: v.$fs-200;
  @include mq(md, "min") {
    font-size: v.$text-size;
  }
}

@mixin headline() {
  font-kerning: normal;
  text-transform: uppercase;
  font-family: v.$font-display;
  line-height: 96%;
  transition: transform 0.4s v.$easing-primary, padding 0.4s v.$easing-primary;
  font-size: v.$fs-400;
  font-variation-settings: "wght" 120, "wdth" 80;
  @include mq(md, "min") {
    font-size: clamp(v.$fs-300, 3.8vw, v.$fs-500);
  }
}

@mixin horizontal-line() {
  content: "";
  display: block;
  position: absolute;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  height: 1px;
  width: calc(98vw - (2 * v.$sp-md));
  background-color: v.$secondary-100;
}

@mixin column() {
  row-gap: 3rem;
  column-gap: 32%;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
}

@mixin icon-size() {
  min-width: 1.8rem;
  min-height: 1.8rem;
  max-width: 4.8rem;
  max-height: 4.8rem;
  width: 3.6vw;
  height: 3.6vw;
}

@mixin hidden() {
  visibility: hidden;
  pointer-events: none;
  display: none;
}

@mixin focus() {
  outline: 0.4rem solid #fff78e;
}
