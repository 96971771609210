@use "../abstracts" as a;

.flash-banner {
  display: flex;
  min-width: max-content;
  animation: 32s slide infinite linear;

  & a,
  & span {
    flex-shrink: 0;
    padding-left: a.$sp-xs;
  }

  @at-root #{&}__wrapper {
    overflow: hidden;
    position: relative;
    padding: a.$sp-sm 0;
    background-color: a.$primary-100;

    &:hover .flash-banner {
      animation-play-state: paused;
    }
  }

  @at-root #{&}__link {
    text-decoration: none;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      width: 98%;
      height: 1px;
      background-color: a.$secondary-100;
      bottom: 2px;
      left: a.$sp-sm;
    }
  }
}
